import { Injectable, OnDestroy, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OrgAccessControls } from '@garmin-avcloud/avcloud-fly-web-common/orgs';
import { Permission } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { BehaviorSubject, finalize, forkJoin, Observable } from 'rxjs';
import { AuthorizationService } from '../authorization.service';

@Injectable({
  providedIn: 'root'
})
export class OrgAccessControlsService implements OrgAccessControls, OnDestroy {
  readonly canViewOrgSettings = signal(false);
  readonly canEditOrgSettings = signal(false);
  readonly canViewOrgMemberships = signal(false);
  readonly canCreateOrgMemberships = signal(false);
  readonly canDeleteOrgMemberships = signal(false);
  readonly canViewOrgMembershipInvites = signal(false);
  readonly canCreateOrgMembershipInvites = signal(false);
  readonly canDeleteOrgMembershipInvites = signal(false);
  readonly canViewOrgAircraft = signal(false);
  readonly canEditOrgAircraft = signal(false);
  readonly canViewOrgRoles = signal(false);
  readonly canEditOrgRoles = signal(false);

  private readonly authorizationService = inject(AuthorizationService);
  private readonly areAccessControlsReady$ = new BehaviorSubject(false);

  constructor() {
    const hasOrgEditPermission$ = this.authorizationService.hasPermission(Permission.ORGANIZATION_EDIT);
    const hasMemberReadPermission$ = this.authorizationService.hasPermission(Permission.MEMBER_READ);
    const hasMemberEditPermission$ = this.authorizationService.hasPermission(Permission.MEMBER_EDIT);
    const hasAircraftReadPermission$ = this.authorizationService.hasPermission(Permission.AIRCRAFT_READ);
    const hasAircraftEditPermission$ = this.authorizationService.hasPermission(Permission.AIRCRAFT_EDIT);
    const hasOrgRolesEditPermission$ = this.authorizationService.hasPermission(Permission.ORGANIZATION_ROLES_EDIT);

    forkJoin([
      hasOrgEditPermission$,
      hasMemberReadPermission$,
      hasMemberEditPermission$,
      hasAircraftReadPermission$,
      hasAircraftEditPermission$,
      hasOrgRolesEditPermission$
    ])
      .pipe(
        finalize(() => this.areAccessControlsReady$.next(true)), // on error, the initial false values for the access controls are valid
        takeUntilDestroyed()
      )
      .subscribe(
        ([
          hasOrgEditPermission,
          hasMemberReadPermission,
          hasMemberEditPermission,
          hasAircraftReadPermission,
          hasAircraftEditPermission,
          hasOrgRolesEditPermission
        ]) => {
          this.canViewOrgSettings.set(hasOrgEditPermission);
          this.canEditOrgSettings.set(hasOrgEditPermission);
          this.canViewOrgMemberships.set(hasMemberReadPermission);
          this.canCreateOrgMemberships.set(hasMemberEditPermission);
          this.canDeleteOrgMemberships.set(hasMemberEditPermission);
          this.canViewOrgMembershipInvites.set(hasMemberEditPermission);
          this.canCreateOrgMembershipInvites.set(hasMemberEditPermission);
          this.canDeleteOrgMembershipInvites.set(hasMemberEditPermission);
          this.canViewOrgAircraft.set(hasAircraftReadPermission);
          this.canEditOrgAircraft.set(hasAircraftEditPermission);
          this.canViewOrgRoles.set(hasMemberReadPermission); // MEMBER_READ suffice to view Org's roles.
          this.canEditOrgRoles.set(hasOrgRolesEditPermission);
        }
      );
  }

  ngOnDestroy(): void {
    this.areAccessControlsReady$.complete();
  }

  areAccessControlsReady(): Observable<boolean> {
    return this.areAccessControlsReady$;
  }
}
